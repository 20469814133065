<template>
  <v-dialog v-model="show" max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ ingrediente_interno.ingrediente }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="ingrediente_interno.preco"
                label="Preço"
                @change="calcule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="close">
          Fechar
        </v-btn>
        <v-btn depressed color="error" @click="save">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PrecoEditor",
  props: {
    value: { type: Boolean, default: false },
    ingrediente: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ingrediente_interno: {},
    defaultValues: {
      id: null,
      preco: "0",
    },
  }),
  mounted() {
    this.ingrediente_interno = Object.assign(
      {},
      this.defaultValues,
      this.ingrediente
    );
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      val || this.close();
    },
    ingrediente(val) {
      val
        ? (this.ingrediente_interno = Object.assign(
            {},
            this.defaultValues,
            val
          ))
        : (this.ingrediente_interno = Object.assign({}, this.defaultValues));
    },
  },
  methods: {
    calcule(e) {
      this.ingrediente_interno.preco = String(eval(e));
    },
    close() {
      this.show = false;
      this.$nextTick(() => {
        this.ingrediente_interno = Object.assign({}, this.defaultValues);
      });
    },
    save() {
      this.$emit("save", Object.assign({}, this.ingrediente_interno));

      this.close();
    },
  },
};
</script>
