<template>
    <v-tabs
      v-model="periodo_selecionado"
      hide-slider
      tab-fin
      background-color="primary"
      height="78px"
      show-arrows
    >
      <!-- <v-tabs-slider color="teal lighten-3"></v-tabs-slider> -->
      <v-tab
        v-for="periodo in periodos"
        :key="periodo.periodo"
        :href="`#${periodo.periodo}`"
        @click.ctrl="$emit('ano')"
      >
        <div class="text-center">
          <span class="body-1 font-weight-bold"
            >{{ periodo.mes_ext }} {{ periodo.ano }}</span
          >

          <br />
          <!-- <span style="{display:inline-block;height:1px;float:left;clear:left}"></span> -->
          <!-- <br/> -->
          <!--  <span class="caption">R$ {{ resumo.balanco }}</span> -->
        </div>
      </v-tab>
    </v-tabs>
</template>

<script>
export default {
  name: "Periodos",
  props: {
    periodos: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({ periodo: "", mex_ext: "", ano: 0, mes: 0 }),
    },
    /*     transacoes: {
      type: Array,
      default: () => [],
    }, */
  },
  data: () => ({ periodo_selecionado: null, map_periodos: new Map() }),
  methods: {
    carregaPeriodos() {
     if (!this.periodos || this.periodos?.length===0) return 
      const { periodo } = this.periodos[this.periodos.length - 1];
      this.periodo_selecionado = this.value || periodo;
      this.map_periodos.clear();
      this.periodos.forEach((v) => {
        this.map_periodos.set(v.periodo, v);
      });
    },
  },
  mounted()  {
      this.carregaPeriodos();
  
    
  },
  watch: {
    periodo_selecionado: function(val) {
      this.$emit("input", this.map_periodos.get(val));
    },
    periodos: {
      deep: true,
      handler() {
        this.carregaPeriodos();
      },
    },
  },
};
</script>
