<template>
  <div class="home">
    <preco-editor
      v-model="dialog_preco"
      :ingrediente="precoEditado"
      @save="salvarPreco"
    ></preco-editor>
    <IngredienteEditor
      v-model="dialog_ingrediente"
      :ingrediente="ingredienteEditado"
      @save="salvarIngrediente"
    ></IngredienteEditor>
    <v-card>
      <v-card-title>
        <v-row>
          Ingredientes
          <v-spacer></v-spacer>
          <v-text-field
            v-model="busca"
            append-icon="mdi-magnify"
            label="Busca"
            single-line
            hide-details
          ></v-text-field
        ></v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="ingredientes"
        sort-by="ingrediente"
        class="elevation-1"
        :search="busca"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editarIngrediente(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="apagarIngredientes(item)">
            mdi-delete
          </v-icon>
          <v-icon small @click="editarPreco(item)">
            mdi-cash
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="fetch_ingredientes">
            Carregar
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

/* 
{
      "id": 0,
      "ingrediente": "Chocolate Branco Melken",
      "tags": null,
      "preco": "42.76",
      "acucares": "60",
      "solidos_diferentes": "0",
      "solidos_totais": "96.8",
      "pod": "52.761",
      "pac": "1",
      "lactose": "8.617850",
      "frutose": "0",
      "dextrose_glicose": "0",
      "calorias": "544",
      "vol_alcoolico": "0",
      "sais": "0",
      "polissacarideos": "0",
      "fibra": "0",
      "fibra_congelante": "0",
      "gordura": "30.8",
      "gordura_congelante": "18",
      "proteina": "6",
      "colesterol_mg": "0",
      "gordura_saturada": "18.4",
      "gordura_trans": "0",
      "gordura_monoinsaturada": "0",
      "gordura_poliinsaturada": "0",
      "sodio_mg": "76",
      "brix": "0",
      "sacarose": "51.382149",
      "galactose": "0",
      "maltose": "0"
    }
   */
import IngredienteEditor from "../components/IngredienteEditor";
import PrecoEditor from "../components/PrecoEditor";
import { mapGetters, mapActions } from "vuex";
import { ingredientesService } from "./../services/ingredientes.service";
export default {
  name: "Ingredientes",
  components: {
    IngredienteEditor,
    PrecoEditor,
  },
  data: () => ({
    busca: "",
    ingredienteEditado: {},
    precoEditado:{},
    headers: [
      {
        text: "Ingrediente",
        align: "start",
        sortable: false,
        value: "ingrediente",
      },
      { text: "Preco /kg", value: "preco" },
      { text: "Tags", value: "tags" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    dialog_ingrediente: false,
    dialog_preco:false
  }),
  computed: {
    ...mapGetters({
      ingredientes: "ingrediente/ingredientes",
      ingredienteById: "ingrediente/ingredienteById",

    }),
  },
  methods: {
    ...mapActions({
      fetch_ingredientes: "ingrediente/fetch_ingredientes",
      save_ingrediente: "ingrediente/save_ingrediente",
      delete_ingrediente: "ingrediente/delete_ingrediente",
      update_preco: "ingrediente/update_preco",
    }),
    editarPreco: function({ id }) {
      //get ingrediente data
        this.precoEditado = { ...this.ingredienteById(id) };
        this.dialog_preco = true;      
    },
    editarIngrediente: function({ id }) {
      //get ingrediente data
      ingredientesService.getIngredienteById(id).then((ingrediente) => {
        this.ingredienteEditado = { ...ingrediente };
        this.dialog_ingrediente = true;
      });
    },
    apagarIngredientes: function(val) {
      this.delete_ingrediente(val);
    },
    salvarIngrediente: function(val) {
      this.save_ingrediente(val);
      /* this.ingredienteEditado = Object.assign({}, item)
        this.dialog_ingrediente = true */
    },
    salvarPreco: function(val) {
      this.update_preco(val).then();
      /* this.ingredienteEditado = Object.assign({}, item)
        this.dialog_ingrediente = true */
    },
  },
};
</script>
