<template>
  <div class="receita">
    <v-row><br /></v-row>
    <v-row>
      <v-col cols="4" sm="12" md="4">
        <v-btn outlined small @click="nova_receita"
          ><v-icon left> mdi-plus-circle </v-icon> Nova
        </v-btn>
        <v-btn outlined small @click="duplicate_receita">
          <v-icon left> mdi-plus-circle-multiple </v-icon> Duplicar
        </v-btn>
        <v-btn outlined small @click="receita_save">
          <v-icon left> mdi-content-save </v-icon> Salvar
        </v-btn></v-col
      ></v-row
    ><v-row>
      <v-col cols="2" sm="12" md="2">
        <v-select
          label="Receitas"
          hint="Escolha uma receita para carregar"
          persistent-hint
          :items="receitas"
          item-text="receita"
          item-value="id"
          dense
          v-model="receita_id"
          @change="ler_receita"
        >
        </v-select>
      </v-col>
      <v-col cols="2" sm="12" md="2">
        <v-text-field
          label="Quantidade kg"
          dense
          v-model="fatorReceita"
          @change="calcule"
        >
        </v-text-field
      ></v-col>
      <v-col cols="2" sm="12" md="2">
        <v-text-field
          label="Rendimento"
          dense
          type="number"
          v-model.number="rendimentoReceita"
        >
        </v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="12" md="4">
        <v-text-field
          label="Receita"
          type="text"
          v-model="receita.receita"
        ></v-text-field
      ></v-col>
      <v-col cols="4" sm="12" md="4">
        <v-combobox
          v-model="receita.tags"
          hide-selected
          hint="Maximum of 5 tags"
          label="Tags"
          multiple
          small-chips
        >
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" sm="12" md="3">
        <v-select
          label="Ingredientes"
          hint="Escolha o ingrediente para a receita"
          persistent-hint
          :items="ingredientes"
          item-text="ingrediente"
          item-value="id"
          v-model="item"
          dense
        >
        </v-select>
      </v-col>
      <v-col cols="2" sm="12" md="2">
        <v-text-field
          label="Quantidade"
          type="number"
          v-model.number="quantidade"
          @click:prepend="ingrediente_add"
          @click:append="complemento_add"
          prepend-icon="mdi-playlist-plus"
          append-icon="mdi-hamburger-plus"
          dense
        >
        </v-text-field>
        <!--         <button
          @click="ingrediente_add"
        >
          Add
        </button><button
          @click="complemento_add"
        >
          Add Comp
        </button> --></v-col
      ></v-row
    ><v-row>
      <v-col cols="6" sm="12" md="6">
        <table>
          <thead>
            <tr>
              <!--  <th>Código</th> -->
              <th>Ingrediente</th>
              <th style="witdh: 60px">Quantidade</th>
              <th>Brix</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ingrediente in receita.ingredientes"
              :key="ingrediente.id"
            >
              <!-- <td>{{ ingrediente._id }}</td> -->
              <td style="padding-right: 10px">
                {{ ingrediente.ingrediente }}
              </td>
              <td>
                <input
                  style="width: 100px"
                  type="number"
                  v-model="ingrediente.quantidade"
                  @change="update_ingrediente(ingrediente)"
                />
              </td>
              <td>
                <input
                  style="width: 60px"
                  type="number"
                  v-model="ingrediente.brix"
                  @change="update_ingrediente(ingrediente)"
                />
              </td>
              <td>
                <a
                  @click.exact="delete_ingrediente(ingrediente)"
                  @click.ctrl="replace_ing_by_selected(ingrediente)"
                  >X</a
                >
              </td>
            </tr>
            <tr
              class="orange lighten-5"
              v-for="complemento in receita.complementos"
              :key="complemento.id"
            >
              <!-- <td>{{ ingrediente._id }}</td> -->
              <td style="padding-right: 10px">
                {{ complemento.ingrediente }}
              </td>
              <td>
                <input
                  style="width: 100px"
                  type="number"
                  v-model="complemento.quantidade"
                  @change="update_complemento(complemento)"
                />
              </td>
              <td>
                <input
                  style="width: 60px"
                  type="number"
                  v-model="complemento.brix"
                  @change="update_complemento(complemento)"
                />
              </td>
              <td>
                <a
                  @click.exact="delete_complemento(complemento)"
                  @click.ctrl="replace_comp_by_selected(complemento)"
                  >X</a
                >
              </td>
            </tr>
            <tr>
              <td>R$ {{ numero(balanceamento.preco) }}</td>
              <td></td>
              <td></td>
              <td>Total: {{ totalDaReceita }}</td>
            </tr>
          </tbody>
        </table></v-col
      >
      <v-col cols="2" sm="12" md="2">
        <table>
          <tr class="caption">
            <td>Açucares</td>
            <td>Lactose</td>
          </tr>
          <tr>
            <td>{{ numero(balanceamento.acucares) }}</td>
            <td>
              <span
                :class="{
                  'red--text lighten-2': balanceamento.fatorLactose >= 1,
                }"
              >
                {{ numero(balanceamento.lactose) }}</span
              >
            </td>
          </tr>
          <tr class="caption">
            <td>Gordura</td>
            <td>Proteínas</td>
          </tr>
          <tr>
            <td>{{ numero(balanceamento.gordura) }}</td>
            <td>{{ numero(balanceamento.proteina) }}</td>
          </tr>
          <tr class="caption">
            <td>Solidos Totais</td>
            <td>Outros sólidos</td>
          </tr>
          <tr>
            <td>{{ numero(balanceamento.solidos_totais) }}</td>
            <td>{{ numero(balanceamento.solidos_diferentes) }}</td>
          </tr>
          <tr class="caption">
            <td>POD</td>
            <td>PAC</td>
          </tr>
          <tr>
            <td>{{ numero(balanceamento.pod) }}</td>
            <td>{{ numero(balanceamento.pac) }}</td>
          </tr>
          <tr class="caption">
            <td>Temperatura °C</td>
            <td>Vol Alcoolico</td>
          </tr>
          <tr>
            <td>{{ numero(balanceamento.temperatura) }}</td>
            <td>{{ numero(balanceamento.vol_alcoolico) }}</td>
          </tr>
          <tr class="caption">
            <td>Calorias</td>
            <td>Sodio mg</td>
          </tr>
          <tr>
            <td>{{ balanceamento.calorias }}</td>
            <td>{{ balanceamento.sodio_mg }}</td>
          </tr>
        </table>
      </v-col>
      <v-col cols="3" sm="12" md="3">
        <div class="body-2" style="white-space: pre">{{ receitaTexto }}</div>
      </v-col>
    </v-row>

    <button @click="receita_load">Carrega</button>

    <!-- <table class="table">
      <thead>
        <tr>
          <th class="text-center">
            <h4>Gorduras</h4>
          </th>
          <th class="text-center">
            <h4>S.N.G.</h4>
          </th>
          <th class="text-center">
            <h4>Açúcar</h4>
          </th>
          <th class="text-center">
            <h4>Sólidos Totais</h4>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colspan="3">
            <span
              >*S.N.G São Sólidos Não Gordurosos, podendo ser de leite, ou
              sólidos não gordurosos provenientes de outros ingredientes</span
            >
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr>
          <td class="text-center">3%</td>
          <td class="text-center">14 – 15%</td>
          <td class="text-center">14 – 18%</td>
          <td class="text-center">31 – 36%</td>
        </tr>
        <tr>
          <td class="text-center">4%</td>
          <td class="text-center">12 – 14%</td>
          <td class="text-center">14 – 18%</td>
          <td class="text-center">30 – 36%</td>
        </tr>
        <tr>
          <td class="text-center">5%</td>
          <td class="text-center">11 – 13%</td>
          <td class="text-center">14 – 18%</td>
          <td class="text-center">30 – 36%</td>
        </tr>
        <tr>
          <td class="text-center">6%</td>
          <td class="text-center">11 – 13%</td>
          <td class="text-center">14 – 18%</td>
          <td class="text-center">31 – 37%</td>
        </tr>
        <tr>
          <td class="text-center">8%</td>
          <td class="text-center">10 – 12%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">34 – 38%</td>
        </tr>
        <tr>
          <td class="text-center">10%</td>
          <td class="text-center">10 – 11%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">36 – 39%</td>
        </tr>
        <tr>
          <td class="text-center">12%</td>
          <td class="text-center">9 – 10%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">37 – 40%</td>
        </tr>
        <tr>
          <td class="text-center">14%</td>
          <td class="text-center">8 – 9%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">38 – 41%</td>
        </tr>
        <tr>
          <td class="text-center">16%</td>
          <td class="text-center">7 – 8%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">39 – 42%</td>
        </tr>
        <tr>
          <td class="text-center">18%</td>
          <td class="text-center">6 – 7%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">40 – 43%</td>
        </tr>
        <tr>
          <td class="text-center">20%</td>
          <td class="text-center">5 – 6%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">41 – 44%</td>
        </tr>
      </tbody>
    </table> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
/* 
{
  "quantidade": "1000",
  "preco": "6.28",
  "lactose": "35.54",
  "gordura": "49.29",
  "proteina": "28.02",
  "vol_alcoolico": "0",
  "calorias": "0",
  "pod": "158.82",
  "pac": "204.99",
  "acucares": "189.67",
  "solidos_diferentes": "65.04",
  "solidos_totais": "332.01",
  "sodio_mg": "602.43",
  "temperatura": "-8.25",
  "fatorLiga": "0.93496",
  "fatorLactose": "0.4433699107272065"
}

*/
export default {
  name: "Receita",
  data: () => ({
    item: 0,
    quantidade: 0,
    receitaLocal: {},
    fatorReceita: 1,
    receita_id: 0,
    rendimentoReceita: 7,
    rec: {
      nome: "",
      apresentacao: "picole",
    },
  }),
  computed: {
    ...mapGetters({
      ingredientes: "ingrediente/ingredientes",
      receitas: "receita/receitas",
      produtos: "ingrediente/produtos",
      receita: "receita/receita",
      balanceamento: "receita/balanceamento",
      receitaEmVolume: "receita/receitaEmVolume",
    }),

    receitaTexto: function() {
      if (
        this.receita.ingredientes.length === 0 &&
        this.receita.complementos.length === 0
      )
        return "";
      const linha = "\n",
        multiplicador = () => {
          return this.balanceamento.fatorLiga
            ? this.balanceamento.fatorLiga * this.fatorReceita
            : 0 ;
        };
      let complementos = this.receitaProporcial.complementos
          .map(({ quantidade, ingrediente, custo }) => {
            return `${+quantidade + ""} ${ingrediente} R$ ${custo.replace(
              ".",
              ","
            )}`;
          })
          .join(linha),
        ingredientes = this.receitaProporcial.ingredientes
          .map(({ quantidade, ingrediente, custo, brix }) => {
            return `${+quantidade + ""} ${ingrediente} R$ ${custo.replace(
              ".",
              ","
            )}${this.brixText(brix)}`;
          })
          .join(linha);
      return [
        `${this.receita.receita ? this.receita.receita.toUpperCase() : ""}`,
        `${linha}Ingredientes`,
        ingredientes,
        " ",
        `${(3 * multiplicador()).toFixed(2)} Liga Industrial, ${(
          5 * multiplicador()
        ).toFixed(2)} Liga Aqua, ${(10 * multiplicador()).toFixed(
          2
        )} Liga 10g/kg`,
                `POD ${this.balanceamento.pod}, PAC: ${this.balanceamento.pac}`,

        complementos.length > 0 ? `${linha}Complementos` : null,
        complementos,
        `${linha}R$ ${(this.balanceamento.preco * this.fatorReceita)
          .toFixed(2)
          .replace(".", ",")} - R$ ${(
          (this.balanceamento.preco * this.fatorReceita) /
          this.rendimentoReceita
        )
          .toFixed(2)
          .replace(".", ",")} / Un`,
      ].join(linha);
    },
    receitaProporcial: function() {
      return this.receitaEmVolume(this.fatorReceita);
    },
    totalDaReceita: function() {
      let total = 0;
      this.receita.ingredientes.forEach(({ quantidade }) => {
        total += +quantidade;
      });
      return total;
    },

    ingredientesProporcionais: function() {
      const total = this.volReceita / 1000,
        liquidos =
          (this.volReceita / 1000) *
          (1 - this.balanceamento.solidos_totais / 1000),
        fatorLiga = this.balanceamento.fatorLiga;
      return {
        ligaIndustrial: 3 * fatorLiga,
        ligaAcquaNoLeite: 3.5 * fatorLiga,
        ligaAcqua: 5 * fatorLiga,
        ligaSuper: 10 * fatorLiga,
        emustab: 10 * total,
        emustab2: 10 * liquidos,
      };
    },
  },
  mounted() {
    this.fetch_ingredientes();
    this.fetch_receitas();
  },
  methods: {
    ...mapActions({
      fetch_ingredientes: "ingrediente/fetch_ingredientes",
      fetch_receitas: "receita/fetch_receitas",
      nova_receita: "receita/nova_receita",
      fetch_receitaById: "receita/fetch_receitaById",
      add_produto: "ingrediente/add_produto",
      add_ingrediente: "receita/add_ingrediente",
      delete_ingrediente: "receita/delete_ingrediente",
      update_ingrediente: "receita/update_ingrediente",
      replace_ingrediente: "receita/replace_ingrediente",

      add_complemento: "receita/add_complemento",
      delete_complemento: "receita/delete_complemento",
      update_complemento: "receita/update_complemento",
      replace_complemento: "receita/replace_complemento",

      receita_save: "receita/save_receita",
      receita_load: "ingrediente/load_receita",
      update_receita: "receita/update_receita",
      duplicate_receita: "receita/duplicate_receita",
    }),
    brixText(e) {
      let brix = Number(e).toFixed(2)
      if (!e || +brix===0) return ""
      return `, ${brix}º Brix`
    },
    calcule(e) {
      if (isNaN(e)) {
        try {
          this.fatorReceita = eval(e.replace(",", "."));
        } catch {
          this.fatorReceita = 1;
        }
        
      }
    },
    replace_ing_by_selected: function({ id, quantidade }) {
      this.replace_ingrediente({ id, quantidade, replace_id: this.item });
    },
    replace_comp_by_selected: function({ id, quantidade }) {
      this.replace_complemento({ id, quantidade, replace_id: this.item });
    },
    ler_receita: function() {
      this.fetch_receitaById(this.receita_id).then(() => {
        /*   const {receita, tags} = this.receita
        this.receitaLocal = {receita,tags}; */
      });
    },
    numero: (n) => {
      return n ? n.replace(".", ",") : "0";
    },
    ingrediente_add: function() {
      this.add_ingrediente({
        id: this.item,
        quantidade: this.quantidade,
      });
      (this.item = 0), (this.quantidade = 0);
    },
    complemento_add: function() {
      this.add_complemento({
        id: this.item,
        quantidade: this.quantidade,
      });
      (this.item = 0), (this.quantidade = 0);
    },
  },
};
</script>
