<template>
  <v-container>
    <v-card>
      <v-row>
        <v-col cols="12" sm="4" md="2">
          <v-text-field
            label="Data"
            outlined
            dense
            v-model="transacao.date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-select
            :items="contas"
            label="De:"
            item-value="_id"
            item-text="nome"
            outlined
            dense
            v-model="transacao.de"
          ></v-select
        ></v-col>
        <v-col cols="12" sm="4" md="2"
          ><v-select
            :items="contas"
            label="Para:"
            item-value="_id"
            item-text="nome"
            outlined
            dense
            v-model="transacao.para"
          ></v-select
        ></v-col>

        <v-col cols="12" sm="4" md="2">
          <v-text-field
            label="Valor"
            outlined
            dense
            v-model="transacao.amount"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-btn color="success" @click="save" :disabled="!balanced"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="transacoes"
        :items-per-page="-1"
        item-key="_id"
        class="elevation-0"
      ></v-data-table>
      <!-- <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="{
              _transacao,
              de,
              para,
              credito,
              debito,
              data,
            } in transacoes"
            :key="_transacao"
          >
            <v-list-item-content>
              <v-list-item
                v-text="`De: ${de} ${credito.replace('.', ',')}`"
              ></v-list-item>
              <v-list-item
                v-text="`Para:${para} ${debito.replace('.', ',')}`"
              ></v-list-item>
              <v-list-item
                v-text="`Em ${data}`"
              ></v-list-item> </v-list-item-content></v-list-item>
        </v-list-item-group>
      </v-list> -->
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Transacoes",
  props: {
    contas: {
      type: Array,
      default: () => [],
    },
    transacoes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    transacao: {
      de: null,
      para: null,
      amount: "0,00",
      date: "",
    },
    headers: [
      { text: "Data", value: "date" },
      {
        text: "De",
        align: "start",
        sortable: false,
        value: "de",
      },
      { text: "Para", value: "para" },
      { text: "Valor", value: "amount" },
    ],
  }),
  created() {
    
    this.transacao.date = /(\d{4})-(\d{2})-(\d{2})/g.exec(new Date().toISOString()).slice(1,4).reverse().join('/');
  },
  methods: {
    save: function() {
      const { de, para, amount, date } = this.transacao;
      let valor = Number(amount.replace(",", ".")).toFixed(2);
      this.$emit("click:save", {
        de,
        para,
        amount:valor,
        date: `${date
          .split("/")
          .reverse()
          .join("-")}T00:00:00.000Z`,
      });
    },
    reset_transacao: function() {
      Object.assign(this.transacao, {
        de: null,
        para: null,
        amount: "0,00",
        date: "",
      });
    },
  },
  computed: {
    balanced: function() {
      return true;
      /* (
        Number(this.transacao.credito.replace(",", ".")) ===
        Number(this.transacao.debito.replace(",", "."))
      ) */
    },
  },
};
</script>
