<template>
  <div class="financeiro">
    <v-row>
      <v-col cols="12" sm="12" md="12"
        ><periodos :periodos="periodos" v-model="periodo_selecionado" @ano="ano= !ano"></periodos
      ></v-col>
    </v-row>
    <!-- lista de periodos -->
    <v-row>
      <v-col cols="8" sm="12" md="8">
        <!-- transacoes -->
        <Transacoes
          :contas="contas"
          :transacoes="transacoes"
          @click:save="inserir_transacoes($event)"
      /></v-col>
      <v-col cols="4" sm="12" md="4">
        <v-card>
          <v-card-title> Balanco do periodo</v-card-title>
          <v-card-text>
            <p
              v-for="({ nome, credito, debito }, index) of balanco"
              :key="index"
            >
              Conta: {{ nome }}, crédido: {{ credito }} débito: {{ debito }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- balanco do periodo selecionado -->
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import Transacoes from "./../components/Transacoes";
import Periodos from "./../components/Periodos";
export default {
  name: "Financeiro",
  components: { Transacoes, Periodos },
  computed: {
    ...mapGetters({
      contas: "financeiro/contas",
      transacoes: "financeiro/transacoes",
      periodos: "financeiro/periodos",
      balanco: "financeiro/balanco",
    }),
  },
  data() {
    return { periodo_selecionado: null ,
    ano:false};
  },

  methods: {
    ...mapActions({
      inserir_transacoes: "financeiro/inserir_transacoes",
      fetch_balanco: "financeiro/fetch_balanco",
    }),
  },
  watch: {
    periodo_selecionado: function(periodo) {
      if (periodo) {
        const per = {...periodo};
        if (this.ano) delete per['mes']
        this.fetch_balanco({ ...per });
      }
    },
  },
};
</script>
