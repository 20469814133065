<template>
  <v-dialog v-model="show" max-width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        <v-icon>mdi-note-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Ingrediente</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="ingrediente_interno.ingrediente"
                label="Ingrediente"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <v-combobox
                v-model="ingrediente_interno.tags"
                hide-selected
                label="Tags"
                multiple
                small-chips
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="1" md="1">
              <v-text-field
                v-model="ingrediente_interno.brix"
                label="Brix °"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.pod"
                label="POD"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.pac"
                label="PAC"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.sacarose"
                label="Sacarose"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.lactose"
                label="Lactose"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.frutose"
                label="Frutose"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.dextrose_glicose"
                label="Glicose 100%"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.galactose"
                label="Galactose"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.maltose"
                label="Maltose"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.proteina"
                label="Proteína"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.polissacarideos"
                label="Polissacarídeos"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.sais"
                label="Sais"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.fibra"
                label="Fibra"
                hint="Fibras totais"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.fibra_congelante"
                label="Fibra Cong."
                hint="Fibras insolúveis"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.gordura"
                label="Gordura"
                hint="Gorguras totais"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.gordura_congelante"
                label="Gordura cong"
                hint="Gordura de cacau e oleaginosa"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.gordura_saturada"
                label="Gordura saturada"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.gordura_trans"
                label="Gordura trans"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.gordura_monoinsaturada"
                label="Gordura mono"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.gordura_poliinsaturada"
                label="Gordura poli"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.calorias"
                label="Calorias"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.sodio_mg"
                label="Sódio mg"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.vol_alcoolico"
                label="Vol Álcool"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-text-field
                v-model="ingrediente_interno.colesterol_mg"
                label="Colesterol mg"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            Leite sem lactose possem 99% dos carboidratos dividos entre galactose e glicose e 1% de lactose <br>
            A lactose do chocolate é x% da proteína.</v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="close">
          Fechar
        </v-btn>
        <v-btn depressed color="error" @click="save">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "IngredienteEditor",
  props: {
    value: { type: Boolean, default: false },
    ingrediente: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ingrediente_interno: {},
    defaultValues: {
      id: null,
      ingrediente: "",
      tags: [],
      preco: "0",
      acucares: "0",
      solidos_diferentes: "0",
      solidos_totais: "0",
      pod: "0",
      pac: "0",
      lactose: "0",
      frutose: "0",
      dextrose_glicose: "0",
      calorias: "0",
      vol_alcoolico: "0",
      sais: "0",
      polissacarideos: "0",
      fibra: "0",
      fibra_congelante: "0",
      gordura: "0",
      gordura_congelante: "0",
      proteina: "0",
      colesterol_mg: "0",
      gordura_saturada: "0",
      gordura_trans: "0",
      gordura_monoinsaturada: "0",
      gordura_poliinsaturada: "0",
      sodio_mg: "0",
      brix: "0",
      sacarose: "0",
      galactose: "0",
      maltose: "0",
    },
  }),
  mounted() {
    this.ingrediente_interno = Object.assign(
      {},
      this.defaultValues,
      this.ingrediente
    );
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      val || this.close();
    },
    ingrediente(val) {
      val
        ? (this.ingrediente_interno = Object.assign(
            {},
            this.defaultValues,
            val
          ))
        : (this.ingrediente_interno = Object.assign({}, this.defaultValues));
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$nextTick(() => {
        this.ingrediente_interno = Object.assign({}, this.defaultValues);
      });
    },
    save() {
      this.$emit("save", Object.assign({}, this.ingrediente_interno));

      this.close();
    },
  },
};
</script>
