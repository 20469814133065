<template>
  <div class="about">
    <h1>This is an about page</h1>
    Quantidade <input type="number" v-model.number="volReceita" /> /g <br>
    Rendimento <input type="number" v-model.number="rendimentoReceita" /> /unidades

    <!-- <button @click="novo">Novo</button> -->

    <!--     { "fatSolids": "0.27", "nonFatSolids": "0.00", "sugarSolids": "9.07", "totalSolids": "9.34" }
    -->

    <p>
      Gorduras:{{ balanceamento.solidosGordura }}% - S.N.G:{{
        balanceamento.sngl
      }}% - Açúcar:{{ balanceamento.solidosAcucar }}% - Solidos totais:{{
        balanceamento.solidosTotais
      }}% - PE:{{ balanceamento.pe }}% - PAC:{{ balanceamento.pac }}% - Brix:{{
        balanceamento.brix
      }}%
      <!-- FPDFacts:{{ balanceamento.fpdf }}% -  -->TS {{ balanceamento.ts }}° -
      Alcool {{ balanceamento.alcool }}%
    </p>
    Receitas
    <select v-model="item" @change="ler_receita($event.target.value)">
      <option value></option>
      <option v-for="item in receitas" :key="item._id" :value="item._id">{{
        item.nome
      }}</option>
    </select>
    <input
      type="text"
      v-model="rec.nome"
      @dblclick="
        nova_receita({
          nome: $event.target.value,
          apresentacao: rec.apresentacao,
        })
      "
    />
    <p>{CalculeIngredientesProporcionais}</p>
    <!--     <select v-model="item">
      <option value></option>
      <option v-for="item in ingredientes" :key="item._id" :value="item._id">{{
        item.nome
      }}</option>
    </select> -->
    <select v-model="item">
      <option value></option>
      <option v-for="item in ingredientes" :key="item._id" :value="item._id">{{
        item.nome
      }}</option>
    </select>
    <input type="number" v-model.number="quantidade" /><button
      @click="receita_add"
    >
      Add
    </button>
    <table>
      <thead>
        <tr>
          <!--  <th>Código</th> -->
          <th>Ingrediente</th>
          <th>Quantidade</th>
          <th>Brix</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ingrediente in receita.ingredientes" :key="ingrediente._id">
          <!-- <td>{{ ingrediente._id }}</td> -->
          <td>{{ ingrediente.nome }}</td>
          <td>
            <input
              type="number"
              v-model="ingrediente.qtde"
              @change="update_ingrediente(ingrediente)"
            />
          </td>
          <td>
            <input
              type="number"
              v-model="ingrediente.brix_medido"
              @change="update_ingrediente(ingrediente)"
            />
          </td>
          <td><a @click="receita_delete(ingrediente)">X</a></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>Total: {{ totalDaReceita }}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <tr v-for="(ing, i) in receitaProporcial" :key="i">
        <td>{{ ing.nome }}</td>
        <td>{{ ing.qtde }}</td>
        <td>R$ {{ ing.custo.replace(".",",") }}</td>

      </tr>
        <tr>
        <td><b>Unitário</b></td>
        <td><b>R$ {{(custoTotalDaReceita/rendimentoReceita).toFixed(2).replace(".",",")}} </b></td>
        <td><b>R$ {{((custoTotalDaReceita/rendimentoReceita)*2).toFixed(2).replace(".",",")}} </b></td>
      </tr><tr>
        <td><b>Total</b></td>
        <td><b>R$ {{custoTotalDaReceita.toFixed(2).replace(".",",")}} </b></td>
        <td><b>R$ {{(custoTotalDaReceita*2).toFixed(2).replace(".",",")}} </b></td>
            
      <tr v-for="(v, k) in ingredientesProporcionais" :key="k">
        <td>{{ k }}</td>
        <td>{{ v.toFixed(2) }}</td>
      </tr>
    </table>

    <button @click="receita_save">Salva</button>
    <button @click="receita_load">Carrega</button>

    <!-- <table class="table">
      <thead>
        <tr>
          <th class="text-center">
            <h4>Gorduras</h4>
          </th>
          <th class="text-center">
            <h4>S.N.G.</h4>
          </th>
          <th class="text-center">
            <h4>Açúcar</h4>
          </th>
          <th class="text-center">
            <h4>Sólidos Totais</h4>
          </th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <td colspan="3">
            <span
              >*S.N.G São Sólidos Não Gordurosos, podendo ser de leite, ou
              sólidos não gordurosos provenientes de outros ingredientes</span
            >
          </td>
        </tr>
      </tfoot>
      <tbody>
        <tr>
          <td class="text-center">3%</td>
          <td class="text-center">14 – 15%</td>
          <td class="text-center">14 – 18%</td>
          <td class="text-center">31 – 36%</td>
        </tr>
        <tr>
          <td class="text-center">4%</td>
          <td class="text-center">12 – 14%</td>
          <td class="text-center">14 – 18%</td>
          <td class="text-center">30 – 36%</td>
        </tr>
        <tr>
          <td class="text-center">5%</td>
          <td class="text-center">11 – 13%</td>
          <td class="text-center">14 – 18%</td>
          <td class="text-center">30 – 36%</td>
        </tr>
        <tr>
          <td class="text-center">6%</td>
          <td class="text-center">11 – 13%</td>
          <td class="text-center">14 – 18%</td>
          <td class="text-center">31 – 37%</td>
        </tr>
        <tr>
          <td class="text-center">8%</td>
          <td class="text-center">10 – 12%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">34 – 38%</td>
        </tr>
        <tr>
          <td class="text-center">10%</td>
          <td class="text-center">10 – 11%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">36 – 39%</td>
        </tr>
        <tr>
          <td class="text-center">12%</td>
          <td class="text-center">9 – 10%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">37 – 40%</td>
        </tr>
        <tr>
          <td class="text-center">14%</td>
          <td class="text-center">8 – 9%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">38 – 41%</td>
        </tr>
        <tr>
          <td class="text-center">16%</td>
          <td class="text-center">7 – 8%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">39 – 42%</td>
        </tr>
        <tr>
          <td class="text-center">18%</td>
          <td class="text-center">6 – 7%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">40 – 43%</td>
        </tr>
        <tr>
          <td class="text-center">20%</td>
          <td class="text-center">5 – 6%</td>
          <td class="text-center">16 – 18%</td>
          <td class="text-center">41 – 44%</td>
        </tr>
      </tbody>
    </table> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    item: 0,
    quantidade: 0,
    volReceita: 1000,
    rendimentoReceita:7,
    rec: {
      nome: "",
      apresentacao: "picole",
    },
  }),
  computed: {
    ...mapGetters({
      ingredientes: "ingrediente/ingredientes",
      receitas: "receita/receitas",
      produtos: "ingrediente/produtos",
      receita: "receita/receita",
      balanceamento: "receita/balanceamento",
      receitaEmVolume: "receita/receitaEmVolume",
      receitaEmVolume2: "ingrediente/receitaEmVolume",
    }),
    receitaProporcial: function() {
      return this.receitaEmVolume(this.volReceita);
    },
    totalDaReceita: function() {
      let total = 0;
      this.receita.ingredientes.forEach(({ qtde }) => {
        total += +qtde;
      });
      return total;
    },
     custoTotalDaReceita: function() {
     return this.receitaProporcial.reduce((p,c)=>{
       return p+ +c.custo
     },0)
    },
    ingredientesProporcionais: function() {
      const total = this.volReceita / 1000,
        liquidos =
          (this.volReceita / 1000) *
          (1 - this.balanceamento.solidosTotais / 100);
      return {
        ligaIndustrial: 3 * liquidos,
        ligaAcquaNoLeite: 4 * liquidos,
        ligaAcqua: 5 * liquidos,
        ligaSuper: 10 * liquidos,
        emustab: 10 * total,
        emustab2: 10 * liquidos,
        sabor20g: 20 * liquidos,
        sabor50g: 50 * liquidos,
        salFruta: 0.572 * total,
        salCoco: 0.72 * total,
        salAmendoim: 1.85 * total,
      };
    },
  },
  mounted() {
    this.fetch_ingredientes();
    this.fetch_receitas();
  },
  methods: {
    ...mapActions({
      fetch_ingredientes: "ingrediente/fetch_ingredientes",
      fetch_receitas: "receita/fetch_receitas",
      nova_receita: "receita/nova_receita",
      fetch_receitaById: "receita/fetch_receitaById",
      add_produto: "ingrediente/add_produto",
      add_ingrediente: "receita/add_ingrediente",
      delete_ingrediente: "receita/delete_ingrediente",
      update_ingrediente: "receita/update_ingrediente",
      receita_save: "receita/save_receita",
      receita_load: "ingrediente/load_receita",
    }),
    ler_receita: function(v) {
      this.fetch_receitaById(v).then();
    },
    novo: function() {
      this.add_produto({
        id: 700,
        name: "Acaí Sempre Viva",
        fatSolids: 0.2,
        nonFatSolids: 0,
        sugarSolids: 0.3,
        totalSolids: 0.5,
        product: null,
      });
    },
    receita_add: function() {
      this.add_ingrediente({
        _id: this.item,
        qtde: this.quantidade,
      });
      (this.item = 0), (this.quantidade = 0);
    },
    receita_delete: function(ingrediente) {
      this.delete_ingrediente(ingrediente);
    },
  },
};
</script>
